<template> 
   <div class="col-lg-2 col-6">
      <figure class="lalya">
         <router-link :to="{ name:'Product', params: { productCategoryId : product?.product_category?.url?.content ?? product?.product_category?.id, productId : product?.url?.content ?? product?.id }}">
         <!-- <router-link :to="{ name:'Product', params: { productCategoryId :  product?.product_category?.id, productId : product?.id }}"> -->
         <!-- <router-link :to="url"> -->
            <div class="prod-cat-img">
               <img :src="product.primary_image ?'https://erp44.com/public/admin/product-images/'+product.primary_image.filename:'http://placehold.jp/150x150.png'" class="">
            </div>
            <h4 class="text-center mt-2" style="font-family:'Montserrat',sans-serif;font-size:14px;font-weight:500">{{product?.name?.toUpperCase()}}</h4>
         </router-link>
      </figure>
   </div> 

</template>
<script>
   export default {
        name : 'Product',
          props :['product'],  
   }
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');  
a:link { 
    color: #000095; 
    text-decoration: none;
} 

a:hover {  
    text-decoration:  none;
} 
h4{
   color: #555555; font-weight:300;
    font-family: Verdana, Arial, Helvetica, sans-serif; 
} 
.b_item  { 
    min-height: 115px; 
    padding: 24px 24px 5px 24px; 
    display: inline-block; 
    /* width: calc(25% - 24px);  */ 
    margin: 0 12.5px 25px 12.5px; 
    -webkit-box-shadow: 0px 0px 8px -2px rgb(179 173 179); 
    -moz-box-shadow: 0px 0px 8px -2px rgba(179,173,179,1); 
    box-shadow: 0px 0px 8px -2px rgb(104 102 104);
     transition: transform 0.3s ease;
} 

.b_item:hover  { 
   transform: scale(1.07);
}  

</style>