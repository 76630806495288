<template>
   <div class="container-fluid " > 
         <agile 
            :dots="false" 
            :infinite="true"
            :speed="1000" 
            :timing="'linear'" 
            :fade="true" 
            :navButtons ="false"
            :autoplay="true">
            <div class="slide">
               <img src="../../../../public/assets/images/slider1.jpg" alt=""  style="width:100%;">
            </div>
            <div class="slide">
               <img src="../../../../public/assets/images/slider2.jpg" alt="" width="100%">
            </div>
            <div class="slide">
               <img src="../../../../public/assets/images/slider3.jpg" alt="" width="100%">
            </div>
            <div class="slide">
               <img src="../../../../public/assets/images/slider4.jpg" alt="" width="100%">
            </div>
            <div class="slide">
               <img src="../../../../public/assets/images/slider5.jpg" alt="" width="100%">
            </div>
         </agile> 
   </div>
</template>
<script>
   import { VueAgile } from 'vue-agile'
   export default {
       name:'Slider',
     components: {
       agile: VueAgile
     },
     data(){
         return{
          arrows:false
         }
     }
   }
</script>
<style scoped>
   .slide {
   align-items: center;
   display: flex;
   justify-content: center;
   text-align: center;
   }
   .slide  p {
   color: #805649;
   font-size: 16px;
   font-weight: 300;
   margin-right: 5%;
   margin-left: 5%;
   display: flex;
   text-align: center;
   margin: auto;
   }
   .slide img {
   width: 100%;
   }
</style>