import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './router/index'
import store from './store/main'
import VueAgile from 'vue-agile'  

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
 
import "v3-infinite-loading/lib/style.css"; 

 
import $ from 'jquery'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// // Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// // Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
 

const Vue = createApp(App)   
Vue.use(VueAxios,axios)
Vue.use(store)   
Vue.use(router)
Vue.use(VueAgile)
Vue.use(LoadingPlugin)
Vue.use($)
Vue.mixin({
    data() {
      return { 
          currencySign : localStorage.getItem('currencySign')
      };
    },
    methods: {
      postCoverUrl : (url) => `http://localhost/erp/public/${url}`,
      convertPrice(data){
         if(localStorage.getItem('currency') == 'USD'){
             var result =  parseFloat(data)  * 0.015;
         }else{
             return parseFloat(data);
         } 
         return result.toFixed(2);
     },
    },
})
 

Vue.mount('#app')