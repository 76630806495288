<template> 
      <router-view></router-view>  
</template>
<script>  
export default({
  name: 'App', 
});
</script>
<style>
  .cursor{
    cursor : pointer;
  }
  .gotham{
    font-family: 'Montserrat',sans-serif;
  }
  .btn-success{
    --bs-btn-border : none;
  }
</style>