<template>
   <div>
      <div class="container commonBottomMargin ">
         <div class="row justify-content-center">
            <div class="col-lg-6 col-md-3 col-sm-6 col-12 text-center mt-2">
               <img :src="productAttributes?.primary_image ?'https://erp44.com/public/admin/product-images/'+productAttributes.primary_image.filename:'http://placehold.jp/150x150.png'" class="mt-2">
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt-3 ">
               <h3 class="mt-4 gotham">{{ productAttributes.alias ?? '' }}</h3>
               <p class="gotham">Available in almost every color of the rainbow, the sapphire is truly an iconic gem.<br> In ancient times, its stunning hues catapulted the stone’s position<br>and made it the pride of kings, queens, and aristocrats across the
                  world.
                  <br> Available in almost every color of the rainbow, the sapphire is truly an iconic gem.<br> In ancient times, its stunning hues catapulted the stone’s position<br>and made it the pride of kings, queens, and aristocrats across
                  the world.
               </p>
            </div>
         </div>
      </div>   
      <div class="container-fluid px-5 mt-5 bg-white  ">
         <div class="row">
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     GRADE &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <template  v-for="grade in productAttributes.grade" :key="grade.id">
                        <div v-if="grade.product_stocks_count" @click="toggleGrade(grade.id)" style="cursor:pointer">
                           <input type="checkbox"  v-model="filter.grades"  :value="grade.id"> 
                           {{ grade.alias }} ({{ grade.product_stocks_count }})  
                        </div>
                     </template>
                  </form>
               </div>
            </div>
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     WEIGHT (CARAT) &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <div class="mb-4">
                        <Slider   
                           :min="0"
                           :max="60"
                           v-model="filter.caratRange"
                           />
                     </div>
                     <div class="row">
                        <div class="col-6">
                           <input class="w-100" type="number"  v-model.lazy="filter.caratRange[0]" placeholder="From">
                        </div>
                        <div class="col-6">
                           <input class="w-100"  type="number" v-model.lazy="filter.caratRange[1]" placeholder="To">
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     WEIGHT (RATTI) &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <div class="mb-4">
                        <Slider    
                           :min=0
                           :max=100
                           v-model="filter.rattiRange"
                           />
                     </div>
                     <div class="row">
                        <div class="col-6">
                           <input class="w-100" type="number" v-model.lazy="filter.rattiRange[0]" placeholder="From">
                        </div>
                        <div class="col-6">
                           <input class="w-100"  type="number" v-model.lazy="filter.rattiRange[1]" placeholder="To">
                        </div>
                     </div>
                  </form>
               </div>
            </div>
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     SHAPE &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <template v-for="shape in productAttributes.shape" :key="shape.id">
                        <div v-if="shape.product_stocks_count">
                           <input type="checkbox"  v-model="filter.shapes"  :value="shape.id"> 
                           {{ shape.alias }} ({{ shape.product_stocks_count }})  
                        </div>
                     </template>
                  </form>
               </div>
            </div>
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     COLOUR &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <template v-for="color in productAttributes.colors" :key="color.id">
                        <div v-if="color.product_stocks_count">
                           <input type="checkbox"  v-model="filter.colors"  :value="color.id"> 
                           {{ color.alias }} ({{ color.product_stocks_count }})  
                        </div>
                     </template>
                  </form>
               </div>
            </div>
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     CLARITY &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <template  v-for="clarity in productAttributes.clarity" :key="clarity.id">
                        <div v-if="clarity.product_stocks_count">
                           <input type="checkbox"  v-model="filter.clarities"  :value="clarity.id"> 
                           {{ clarity.alias }} ({{ clarity.product_stocks_count }})  
                        </div>
                     </template>
                  </form>
               </div>
            </div>
         </div>
         <div class="row mt-3">
            <div class="col-md-2">
               <div class="dropdown cursor">
                  <div class="filterclick" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                     ORIGIN &nbsp;  <i class="filterArrow">
                     &nbsp;
                     </i>
                  </div>
                  <form class="dropdown-menu p-4 w-100">
                     <template v-for="origin in productAttributes.origin" :key="origin.id">
                        <div v-if="origin.product_stocks_count">
                           <input type="checkbox"  v-model="filter.origins"  :value="origin.id"> 
                           {{ origin.alias }} ({{ origin.product_stocks_count }})  
                        </div>
                     </template>
                  </form>
               </div>
            </div>
            <div class="col-md-2">
               <div class="dropdown" style="cursor:pointer" @click="fetchItems()">
                  <div class="filterclick">
                     Apply Filters
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="container-fluid mt-5">
         <div class="row commonBottomMargin">
            <div class="col-md-12" >
               <div class="row">
                  <template v-if="items.data.length > 0">
                     <ItemListView v-for="item in items.data" :key="item.id" :item='item'></ItemListView>
                     <template v-if="loading && items.data.length > 0">
                        <InfiniteLoading @infinite="fetchItems" />
                     </template>
                  </template>
                  <template v-else>
                     <div class="justify-content-center">
                        <h1>No Products Found.</h1>
                     </div>
                  </template>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
   import { mapActions, mapGetters } from 'vuex';
   import ItemListView from '../item/ListView.vue';
   import Slider from '@vueform/slider'
   import { HTTP } from '../../../http-common'
   import InfiniteLoading from "v3-infinite-loading";
   export default {
       name: 'Product',
       components : {
          InfiniteLoading,ItemListView, Slider 
       },
       data() {
         return {
            filter: {
               grades: [], 
               colors: [],
               clarities: [],
               shapes: [],
               origins: [],
               product: this.$route.params.productId, 
               rattiRange : [0,100],
               caratRange : [0,60], 
            }, 
            loading : true, 
            items: {
                data : {}
            },
            page : 0,  
            isUpdatingFilter: false,
         }
       },  
       mounted() {
           this.fetchProductAttributes(this.$route.params.productId).then(() => { 
           });
         //   this.fetchRattis(); 
        this.isUpdatingFilter = false;
      const query = this.$route.query; 
      if ('filterGrades' in query) { 
        const filterGrades = Array.isArray(query.filterGrades) ? query.filterGrades : [query.filterGrades];
 
        this.filter.grades = filterGrades;
      }
      if ('filterColors' in query) { 
        const filterColors = Array.isArray(query.filterColors) ? query.filterColors : [query.filterColors];
 
        this.filter.colors = filterColors;
      } 
      if ('filterClarities' in query) { 
        const filterClarities = Array.isArray(query.filterClarities) ? query.filterClarities : [query.filterClarities];
 
        this.filter.clarities = filterClarities;
      } 
      if ('filterShapes' in query) { 
        const filterShapes = Array.isArray(query.filterShapes) ? query.filterShapes : [query.filterShapes];
 
        this.filter.shapes = filterShapes;
      }
      if ('filterOrigins' in query) { 
        const filterOrigins = Array.isArray(query.filterOrigins) ? query.filterOrigins : [query.filterOrigins];
 
        this.filter.origins = filterOrigins;
      }  
      if ('filterMinRatti' in query && 'filterMaxRatti' in query) { 
 
        this.filter.rattiRange[0] = query.filterMinRatti;
        this.filter.rattiRange[1] = query.filterMaxRatti;
      } 
      if ('filterMinCarat' in query && 'filterMaxCarat' in query) { 
 
        this.filter.caratRange[0] = query.filterMinCarat;
        this.filter.caratRange[1] = query.filterMaxCarat;
      } 
 
      this.isUpdatingFilter = true;
      
           this.fetchItems(); 
       }, 
       methods: {
         ...mapActions({ 
               // fetchRattis: 'frontend/fetchRattis',
               fetchProductAttributes: 'frontend/fetchProductAttributes',
         }),  
         toggleGrade(gradeId) {
            // Find the checkbox element corresponding to the gradeId
            const checkbox = document.querySelector(`input[type=checkbox][value="${gradeId}"]`);

            // If the checkbox exists, toggle its checked state
            if (checkbox) {
                  checkbox.checked = !checkbox.checked;

                  // Update the Vue.js data model
                  this.filter.grades = this.filter.grades.includes(gradeId)
                     ? this.filter.grades.filter(id => id !== gradeId)
                     : [...this.filter.grades, gradeId];
            }
         },
        fetchItems() { 
          let loader = this.$loading.show({ 
                    canCancel: true,
                    onCancel: this.onCancel,
            });  
            this.page++; 
             HTTP.post(`product-items?page=${this.page}`,
               {
                filter : this.filter, 
               }
            ).then(({ data }) => {  
               if(this.page == 1){
                  this.items.data = data.data;
               }else{  
                  this.items.data = [...this.items.data, ...data.data]; 
               } 
               if(data.data.length !== 20){
                  this.loading = false;
               }else{
                  this.loading = true;
               }
            }).catch(() =>{
               // this.page = 1
               // this.fetchItems()
            })
            .finally(() => {
               loader.hide() ;
            });
            
            const routeParams = {
                  filterGrades: this.filter.grades, 
                  filterColors: this.filter.colors,
                  filterClarities: this.filter.clarities,
                  filterShapes: this.filter.shapes,
                  filterOrigins: this.filter.origins,
                  filterMinRatti : this.filter.rattiRange[0],
                  filterMaxRatti : this.filter.rattiRange[1],
                  filterMinCarat : this.filter.caratRange[0],
                  filterMaxCarat : this.filter.caratRange[1],
                  filterProduct: this.filter.product,  
               };
               this.$router.replace({ query: routeParams });
       },  
       }, 
       computed: {
           ...mapGetters({ 
             productAttributes: 'frontend/getProductAttributes',   
             products : 'frontend/getProducts',
           }),  
       },
   }
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style scoped> 

@import url('https://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap');  
.filterclick { 
    border: 1px solid #d2d2d2; 
    padding: 11px 12px;
    font-family:  var(--bs-font-sans-serif) !important;
  } 
   i.filterArrow  { 
    height: 8px; 
    margin-left: 0; 
    margin-right: 3px; 
    margin-top: 2px; 
    width: 8px; 
    border-bottom: 2px solid #656565; 
    background-size: contain; 
    transform: rotate(45deg); 
    border-right: 2px solid #656565; 
    display: inline-block; 
    position: absolute; 
    right: 10px;
  } 
  .dropdown-menu{
     --bs-dropdown-border-radius : 0px;
     --bs-dropdown-font-size : 0.8rem;
     font-family: 'Montserrat',sans-serif;
  }
.rev_c1 { 
    /* width: calc(600px - 30px);  */
    padding: 10px 15px 10px 15px; 
    /* float: right;  */
    /* margin: 0 8px 25px 18px;  */
    margin-top:12px !important;
    border: 1px solid #3e3e3e6b; 
    border-radius: 5px; 
    /* position: relative;  */
    /* -webkit-box-shadow: 0px 2px 8px -2px rgba(179,173,179,1);  */
    /* -moz-box-shadow: 0px 2px 8px -2px rgba(179,173,179,1);  */
    /* box-shadow: 0px 2px 8px -2px rgba(179,173,179,1); */
}  

/* Remove arrows from number input */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

/* --------------------------------------------------- */
.header{
     position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}


   *{
   box-sizing: border-box;
   }
   #search{
   outline: none;
   border: none;
   display: inline-block;
   }
   #burgundy{
   color: rgb(153, 40, 59);
   }
   #orange,select,.btn{
   color: orange;
   }
   .fa-search{
   font-size: 20px;
   padding: 10px;
   margin-bottom: 3px;
   padding-right: 20px;
   }
   .search-nav-item{
   height: 40px;
   }
   nav{
   padding: 0px 100px;
   }
   .fa-user-o,.fa-shopping-cart{
   font-size: 20px;
   padding: 4px;
   }
   .form-group{
   margin-bottom: 5px;
   }
   label{
   padding-left: 5px;
   }
   .form-group:last-child{
   margin-bottom: 0;
   }
   h6{
   margin-bottom: 0px;
   }
   #sort{
   outline: none;
   border: none;
   }
   .button-active:active{
   border: 1px solid orange !important;
   border-radius: 10px !important;
   background-color: #fff !important;
   }
   .btn:hover{
   color: #fff;
   background-color: orange;
   }
   .card-body{
   padding: 8px;
   }
   .sign{
   width: 25px;
   height: 25px;
   }
   .discount{
   border: 1px solid orange;
   border-radius: 5px;
   width: 65px;
   position: absolute;
   top: 2%;
   }
   @media(min-width:1200px){
   #search{
   width: 300px;
   padding: 5px;
   padding-left: 20px;
   }
   .search-nav-item{
   margin-left: 400px;
   width: 350px;
   }
   .fa-user-o{
   margin-left: 300px;
   }
   .text{
   display: none;
   }
   .fa-user-o,.fa-shopping-cart{
   font-size: 20px;
   padding-left: 20px;
   }
   #sidebar{
   width: 30%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 70%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   width: 300px;
   height: 330px;
   border: none;
   }
   .card-img-top{
   width: 295px;
   height: 200px;
   border-radius: 10px;
   }
   del{
   padding-right: 2px;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(min-width:992px) and (max-width:1199px){
   #search{
   width: 300px;
   padding: 5px;
   padding-left: 20px;
   }
   .search-nav-item{
   margin-left: 200px;
   width: 350px;
   }
   .fa-user-o{
   margin-left: 160px;
   }
   .text{
   display: none;
   }
   #sidebar{
   width: 30%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 70%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   width: 200px;
   height: 330px;
   border: none;
   }
   .card-img-top{
   width: 200px;
   height: 200px;
   border-radius: 10px;
   }
   .fa-plus,.fa-minus{
   font-size: 12px;
   }
   .sign{
   height: 25px;
   }
   .price{
   width: 99px;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(min-width:768px) and (max-width:991px){
   #search{
   width: 300px;
   padding: 5px;
   padding-left: 20px;
   }
   .search-nav-item{
   margin-left: 60px;
   width: 350px;
   }
   .fa-user-o{
   margin-left: 80px;
   }
   .text{
   display: none;
   }
   #sidebar{
   width: 35%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 65%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   border: none;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(min-width:576px) and (max-width:767px){
   .text{
   display: none;
   }
   .search-nav-item{
   margin-left: 35px;
   width: 270px;
   }
   #search{
   width: 240px;
   padding: 5px;
   padding-left: 20px;
   }
   .fa-search{
   padding: 3px;
   font-size: 18px;
   }
   #sidebar{
   width: 40%;
   padding: 20px;
   float: left;
   }
   #products{
   width: 60%;
   padding: 10px;
   margin: 0;
   float: right;
   }
   .card{
   border: none;
   }
   #off{
   padding-left: 2px;
   }
   #sorting span,#res{
   font-size: 14px;
   }
   .filter,#mobile-filter{
   display: none;
   }
   }
   @media(max-width:575px){
   .search-nav-item{
   margin: 0;
   width: 100%;
   margin-top: 10px;
   }
   #search{
   width: 80%;
   padding-left: 10px;
   margin-top: 10px;
   padding-right: 10px;
   }
   .fa-search{
   padding: 10px;
   font-size: 18px;
   }
   #sidebar{
   display: none;
   }
   .filter{
   margin-left: 70%;
   margin-top: 2%;
   }
   #sorting,#res{
   font-size: 12px;
   margin-top: 2%;
   }
   }
.b_item  { 
    min-height: 115px; 
    padding: 24px 24px 5px 24px; 
    display: inline-block; 
    /* width: calc(25% - 24px);  */ 
    margin: 0 12.5px 25px 12.5px; 
    -webkit-box-shadow: 0px 0px 8px -2px rgb(179 173 179); 
    -moz-box-shadow: 0px 0px 8px -2px rgba(179,173,179,1); 
    box-shadow: 0px 0px 8px -2px rgb(104 102 104);
     transition: transform 0.3s ease;
}
</style>