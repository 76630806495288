<template>
   <header class="header-area header-area-2  bg-ash " > 
      <div class="header-top" style="background-color:  #f7f7f7;  color: #555 !important;">
         <div class="container-fluid">
            <div class="row">
               <div class="col-md-4 col-sm-12 my-auto">  
                  <span class="welcome-text text-center text-sm-center text-md-left" style="color: #555 !important">
                  
                  <span class="pl-1 pr-3" @click="openWhatsApp"> 
                     <img src="/whats_app_icon.png" style="width:30px;" alt="WhatsApp" />   
                  </span>
                  <i class="icon-phone"></i>
                  <span class="pl-1 pr-2">
                  <a target="_blank" :href="whatsAppWithCode">
                  +91{{ domainProfile?.configurationData?.header?.phone }} 
                  </a>
                  </span> 
                  <i class="icon-location-pin"></i> <span class="pl-1 pr-3">
                  {{ domainProfile?.configurationData?.header?.address }}  
                  </span> 
                  
              <i class="fa-solid fa-indian-rupee-sign"></i>
                  </span>  
               </div>
               <div class="col-md-4 col-sm-12 my-auto">
                  <form   method="post" class="header-search" style="margin-top:8px;margin-bottom:8px;">
                     <input type="text" name="search" placeholder="Search for item..." autofocus="" >
                     <button type="submit" name="searchs"><i class="ion-android-search"></i></button>
                  </form>
               </div>
               <div class="col-md-4 col-sm-12 my-auto">
                  <div class="header-top-links mt-2 mt-md-0">
                     <div class="account-wishlist" v-if="!authUser">
                        <router-link :to="{ name:'Login'}" data-toggle="modal" data-target="#loginform"  style="color: #555 !important;"><i class="icon-key" style="color: #555 !important;"></i> Sign In</router-link>
                     </div>
                     <div class="account-wishlist" v-if="authUser">
                        <router-link :to="{name : 'Dashboard'}"><i class="fa-regular fa-user"></i><span class="pl-1">Account</span></router-link> 
                     </div>  
                     
                     <div class="account-wishlist">
                         <router-link  :to="{ name:'Cart'}"> <i class="fa-solid fa-cart-shopping"></i>
                             <span class="ml-1">Cart ({{ cartTotal }})</span> 
                     </router-link>
                     </div>
                     <div class="account-wishlist">
                         <router-link  :to="{ name:'Cart'}"><i class="fa-regular fa-heart"></i>
                             <span class="ml-1">Wishlist ({{ cartTotal }})</span> 
                     </router-link>
                     </div>
                       <div class="account-wishlist" v-if="authUser"> 
                        <a @click.prevent="logout()" style="color: #555 !important;"><i class="icon-logout"></i> <span>  Log out</span></a>
                     </div>  
                  </div>
               </div>
            </div>
         </div>
      </div>   
      <div class="header-top" style="background-color:  #f7f7f7;  color: #555 !important;">
         <div class="container">
            <div class="row"> 
               <div class="col-md-3 col-lg-3 scrollclass">
                  <div class="logo">
                     <router-link class="nav-link p-0"   :to="{ name:'Homepage'}">
                        <img :src="domainProfile?.primary_logo?.path ?? 'https://picsum.photos/500/120'" alt="">
                     </router-link>
                  </div>
               </div>
               <div class="col-md-9 col-lg-9 my-auto">
                  <div class="mainmenu-area header-sticky display-none">
         <div class="container-fluid">
            <div class="menu-wrapper">
               <div class="main-menu">
                  <nav>
                     <ul class="justify-content-center">
                        <li>
                           <router-link class="nav-link"   :to="{ name:'Homepage'}">Home</router-link>
                        </li>
                        <li>
                           <a href="javascript:void(0);">
                           Gemstones             </a>
                           <ul class="sub-menu">
                              <li  v-for="product in products" :key="product.id">
                                 <router-link :to="{ name:'Product', params: { productCategoryId : product?.product_category?.url?.content ?? product?.product_category?.id, productId : product?.url?.content ?? product?.id }}">
                                    {{ product.name }}
                                 </router-link>
                              </li>
                           </ul>
                        </li>
                        <!-- <li  v-for="category in pageCategories" :key="category.id">
                           <template v-if="category?.pages?.length">
                              <a href="javascript:void(0);">{{category.name}}</a>
                              <ul class="sub-menu">
                                 <template v-if="category.pages">
                                    <li  v-for="page in category.pages" :key="page.id">
                                       <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                                    </li>
                                 </template>
                              </ul>
                           </template>
                        </li>
                        <li>
                           <template v-if="nonCategorizedPages?.length">
                              <a href="javascript:void(0);">Non Categorized Pages</a>
                              <ul class="sub-menu">
                                 <template v-if="nonCategorizedPages.length">
                                    <li  v-for="page in nonCategorizedPages" :key="page.id">
                                       <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                                    </li>
                                 </template>
                              </ul>
                           </template>
                        </li> -->
                        <li> 
                           <a class="nav-link" href="#">Gem Guide</a>
                        </li> 
                        
                        <li> 
                           <a class="nav-link" href="#">Gem Recommendation</a>
                        </li> 
                        <li>
                           <a class="nav-link" href="https:blog.9gem.com">Blog</a>
                        </li>
                     </ul>
                  </nav>
               </div>
            </div>
         </div>
      </div>
               </div>
            </div>
         </div>
      </div>
      
      <div class="mobile-menu-area container">
         <div class="mobile-menu">
            <nav class="navbar navbar-expand-sm navbar-light bg-light">
               <button class="navbar-toggler"  @click="toggleClass()" type="button">
               <span class="navbar-toggler-icon"></span>
               </button>
               <div class="collapse navbar-collapse"  :class="{ 'show': isClassActive }" >
                  <ul class="navbar-nav">
                     <li class="nav-item">
                        <router-link @click.native="toggleClass()" class="nav-link"   :to="{ name:'Homepage'}">Home</router-link>
                     </li>
                     <li class="nav-item">
                        <a href="javascript:void(0);">
                        Gemstones             </a>
                        <ul class="sub-menu">
                           <li  v-for="product in products" :key="product.id">
                              <!-- <router-link :to="{name : 'Product', params : {productId :product.id }}">{{ product.name }}</router-link>  -->
                              <router-link :to="{ name:'Product', params: { productCategoryId : product?.product_category?.url?.content ?? product?.product_category?.id, productId : product?.url?.content ?? product?.id }}">
                                 {{ product.name }}
                              </router-link>
                           </li>
                        </ul>
                     </li>
                     <li class="nav-item"  v-for="category in pageCategories" :key="category.id">
                        <template v-if="category?.pages?.length">
                           <a href="javascript:void(0);">{{category.name}}</a>
                           <ul class="sub-menu">
                              <template v-if="category.pages">
                                 <li class="nav-item"  v-for="page in category.pages" :key="page.id">
                                    <router-link @click.native="toggleClass()" :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                                 </li>
                              </template>
                           </ul>
                        </template>
                     </li>
                     <li>
                        <template v-if="nonCategorizedPages?.length">
                           <a href="javascript:void(0);">Non Categorized Pages</a>
                           <ul class="sub-menu">
                              <template v-if="nonCategorizedPages.length">
                                 <li  v-for="page in nonCategorizedPages" :key="page.id">
                                    <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                                 </li>
                              </template>
                           </ul>
                        </template>
                     </li>
                     <li class="nav-item">
                        <router-link class="nav-link" @click.native="toggleClass()"   :to="{ name:'blogIndex'}">Blog</router-link>
                     </li>
                  </ul>
               </div>
            </nav>
            <nav id="mobile-menu-active">
               <ul class="menu-overflow">
                  <li>
                     <router-link class="nav-link"   :to="{ name:'Homepage'}">Home</router-link>
                  </li>
                  <li>
                     <a href="javascript:void(0);">
                     Gemstones             </a>
                     <ul class="sub-menu">
                        <li  v-for="product in products" :key="product.id">
                           <router-link :to="{ name:'Product', params: { productCategoryId : product?.product_category?.url?.content ?? product?.product_category?.id, productId : product?.url?.content ?? product?.id }}">{{ product.name }}</router-link>
                        </li>
                     </ul>
                  </li>
                  <li  v-for="category in pageCategories" :key="category.id">
                     <template v-if="category?.pages?.length">
                        <a href="javascript:void(0);">{{category.name}}</a>
                        <ul class="sub-menu">
                           <template v-if="category.pages">
                              <li  v-for="page in category.pages" :key="page.id">
                                 <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                              </li>
                           </template>
                        </ul>
                     </template>
                  </li>
                  <li  v-for="category in pageCategories" :key="category.id">
                     <template v-if="category?.pages?.length">
                        <a href="javascript:void(0);">{{category.name}}</a>
                        <ul class="sub-menu">
                           <template v-if="category.pages">
                              <li  v-for="page in category.pages" :key="page.id">
                                 <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                              </li>
                           </template>
                        </ul>
                     </template>
                  </li>
                  <li>
                     <template v-if="nonCategorizedPages?.length">
                        <a href="javascript:void(0);">Non Categorized Pages</a>
                        <ul class="sub-menu">
                           <template v-if="nonCategorizedPages.length">
                              <li  v-for="page in nonCategorizedPages" :key="page.id">
                                 <router-link :to="{ name: 'pageView', params: { id: page.url ?? page.id }}">{{page.title}}</router-link>
                              </li>
                           </template>
                        </ul>
                     </template>
                  </li>
                  <li>
                     <router-link class="nav-link"   :to="{ name:'blogIndex'}">Blog</router-link>
                  </li>
               </ul>
            </nav>
         </div>
      </div>
   </header>
</template>
<script>  
   import {mapGetters,mapActions} from 'vuex'
   import {HTTP} from '../../http-common'  
   export default {
      name: 'Header', 
      components : { 
      },
      data(){
          return {
              pageCategories: {},
              isClassActive: false
           } 
      },
      created(){
          this.fetchProducts();
          this.getPageCategories();
          this.fetchCartAll();
          this.fetchPolicyPages();
          this.fetchKnowUsPages();
      },
      computed :{
            ...mapGetters({
               products : 'frontend/getProducts',
               categories : 'frontend/getCategories', 
               authUser : 'auth/authUser',
               domainProfile : 'domain/profile',
               nonCategorizedPages : 'domain/nonCategorizedPages',
               cartTotal : 'cart/cartTotal'
            }),  
            
            email(){
               return `mailto:${this.domainProfile?.email}`;
            },
            auth(){ 
               if(this.$store.state.auth.token){
                     return true;
               }else{
                     return false;
               }
            },
            whatsAppWithCode(){
               return `https://wa.me/${this.domainProfile?.configurationData?.footer?.contact?.phone}`;
            },
            phoneWithCode(){
               return `tel:${this.domainProfile?.configurationData?.header.phone}`;
            }
      },
      methods:{
         ...mapActions({
               fetchProducts : 'frontend/fetchProducts',
               fetchCartAll : 'cart/all',
               fetchPolicyPages : 'frontend/fetchPolicyPages',
               fetchKnowUsPages : 'frontend/fetchKnowUsPages',
         }),
         toggleClass() {
            this.isClassActive = !this.isClassActive;
         },
         getPageCategories : function(){
            HTTP.get(`page-categories-all`).then(res => {  
                   this.pageCategories = res.data.categories
            });
         },
         logout(){  
            this.$store.dispatch('auth/logout').then( () =>{
               this.$router.push({name : 'Homepage',params : {reload : true}});
            });
         },
         openWhatsApp() {
            const phoneNumber = this.domainProfile?.configurationData?.footer?.contact?.phone;
            const whatsappURL = `https://wa.me/${phoneNumber}`;
            window.open(whatsappURL, '_blank');
         }, 

      }, 
   }
      
</script>
<style>
   
.whatsapp-chat-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.whatsapp-chat-icon img {
  width: 50%;
  height: auto;
} 
  .small-svg {
    width: 50px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
  } 

   .header-area-2 {
      position: fixed;
      top: 0;
      width: 100%; 
   }
</style>