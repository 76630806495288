<template>
   <div class="container">
      <div class="col-md-9">
         <div class="card">
            <div class="modal-header" style="background-color: #f7f7f7;">
               <h3 style="color: #555 !important;" class="mb-0 text-center">
                  Review Order
               </h3> 
            </div>
            <div class="a-box order-info card-body">
               <div class="row">
                  <div class="col-md-12">
                     <h4 class="font-weight-bold">Shipping Address :</h4>
                  </div>
                  <div class="col-12">
                     <div class="row card-body"  style="font-size: 14px; line-height: 20px;">
                        <template v-if="addresses.length">
                        <div class="col-md-4" v-for="address  in addresses" :key="address.id">
                           <input type="radio" 
                              v-model="shippingAddressId"
                              v-bind:value="address.id"  
                              > {{address.name ?? 'Contact Name' }}  
                           <br>
                           <span>{{ address.address ??'' }}</span><br>
                           <span>{{ address.city.name ?? '' }} - {{ address.pincode }} - {{  address.state.name ?? '' }}</span><br>
                           <span>{{address.phone ?? 'Contact Number' }}</span>
                           <br/> 
                        </div>
                        </template> 
                             <div class="col-md-4">
                           <router-link  class="a-box p-4  order-info text-center address-box" style="border-style: dashed;" :to="{name : 'AddressCreate', query : {redirectUrl : currentPath}}" > 
                                <h2 class="mt-5"><i class="fa fa-plus"></i></h2>
                                <div>Add address</div> 
                           </router-link>
                     </div>

                     </div> 
                  </div>
               </div>
            </div>
            <div class="a-box order-info card-body">
               <div class="row">
                  <div class="col-md-12">
                     <h4 class="font-weight-bold">Billing Address :</h4>
                  </div>
                  <div class="col-12">
                     <div class="row card-body"  style="font-size: 14px; line-height: 20px;">
                        <template v-if="addresses.length">
                        <div class="col-md-4" v-for="address  in addresses" :key="address.id">
                           <input type="radio" 
                              v-model="billingAddressId"
                              v-bind:value="address.id"  
                              > {{address.name ?? 'Contact Name' }}  
                           <br>
                           <span>{{ address.address ??'' }}</span><br>
                           <span>{{ address.city.name ?? '' }} - {{ address.pincode }} - {{  address.state.name ?? '' }}</span><br>
                           <span>{{address.phone ?? 'Contact Number' }}</span>
                           <br/> 
                        </div>
                        </template> 
                             <div class="col-md-4">
                           <router-link  class="a-box p-4  order-info text-center address-box" style="border-style: dashed;" :to="{name : 'AddressCreate', query : {redirectUrl : currentPath}}" > 
                                <h2 class="mt-5"><i class="fa fa-plus"></i></h2>
                                <div>Add address</div> 
                           </router-link>
                     </div>

                     </div> 
                  </div>
               </div>
            </div>
            <div class="a-box order-info card-body">
               <div class="row">
                  <div class="col-md-12">
                     <h4 class="font-weight-bold">Items :</h4>
                  </div>
                  <div class="col-12">
                     <div class="row card-body">
                        <div class="col-md-12">
                            <div class="table-content table-responsive">
                     <table class="table mb-2">
                        <thead>
                          <tr>
                                       <th class="plantmore-product-thumbnail">Image</th>
                                       <th class="cart-product-name">Product</th>
                                       <th class="cart-product-name">SKU</th>
                                       <th class="plantmore-product-price">Ratti</th>
                                       <th class="plantmore-product-subtotal">Amount</th>
                                    </tr>
                        </thead>
                        <tbody>
                           <tr style="display:none;">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                           </tr>
                           <template v-if="items.items">
                             <tr v-for="item in items.items" :key="item.id">
                                       <td class="plantmore-product-thumbnail"><a><img style="max-width:100px;"  :src="item.certificate_image ? 'https://erp44.com/public/warehouse/certificate-images/'+item.certificate_image.filename:'http://placehold.jp/150x150.png'"   ></a></td>
                                       <td class="plantmore-product-name"><a>{{ domainProfile.id }}{{item.gin}}</a></td>
                                       <td class="plantmore-product-name"><a>{{item.product.alias ?? ''}}</a></td>
                                       <td class="plantmore-product-price"><span class="amount">
                                          {{item.ratti.rati_standard  ?? ''}}+</span>
                                       </td>
                                       <td class="product-subtotal"><span class="amount">
                                      {{ currencySign }}{{convertPrice(item.amounts.mrpAmount)}}
                                          </span>
                                       </td> 
                                    </tr>
                           </template>
                        </tbody>
                     </table>
                  </div>

                           <!-- <div class="row box-inne">
                              <table class="table mb-2">
                                 <thead>
                                    <tr>
                                       <th class="plantmore-product-thumbnail">Image</th>
                                       <th class="cart-product-name">Product</th>
                                       <th class="cart-product-name">SKU</th>
                                       <th class="plantmore-product-price">Ratti</th>
                                       <th class="plantmore-product-subtotal">Amount</th>
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr v-for="item in items.items" :key="item.id">
                                       <td class="plantmore-product-thumbnail"><a><img style="max-width:100px;"  :src="item.certificate_image ? 'https://erp44.com/public/warehouse/certificate-images/'+item.certificate_image.filename:'http://placehold.jp/150x150.png'"   ></a></td>
                                       <td class="plantmore-product-name"><a>{{ domainProfile.id }}{{item.gin}}</a></td>
                                       <td class="plantmore-product-name"><a>{{item.product.alias ?? ''}}</a></td>
                                       <td class="plantmore-product-price"><span class="amount">
                                          {{item.ratti.rati_standard  ?? ''}}+</span>
                                       </td>
                                       <td class="product-subtotal"><span class="amount">
                                      {{ currencySign }}{{convertPrice(item.amounts.mrpAmount)}}
                                          </span>
                                       </td> 
                                    </tr>
                                 </tbody>
                              </table>
                           </div> -->
                        </div>
                     </div>
                  </div>
               </div>
            </div> 
            <div class="a-box order-info card-body">
               
               <div class="row">
                  <div class="col-md-12">
                     <h4 class="font-weight-bold">Payment Method :</h4>
                  </div>
                  <div class="col-12">
                     <div class="row card-body">
                        <div class="col-md-12">
                           <div class="col-md-4" v-for="method  in paymentMethods" :key="method.id">
                              <input type="radio"  
                                 v-model="paymentMethodId"
                                 :value="method.id"  
                                 >   {{ method.name }}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row" style="font-size: 14px; line-height: 18px;">
                  <div class="col-md-3 mb-sm-2">
                  </div>
                  <div class="col-md-4 mb-sm-2">
                  </div>
                  
                     <div class="col-md-5 ml-auto  ">
                        <div class="card" > 
                           <div class="card-header">Total({{ items.items.length }} items) : 
                                      {{ currencySign }}{{convertPrice(items.totalAmount ? items.totalAmount : 0)}} 
                               </div>
                           <div class=""> 
                               <button type="button" class="btn btn-primary float-right"  @click="placeOrder()">Place Order</button> 
                               </div> 
                        </div> 
                     </div> 
               </div> 
            </div> 
         </div>
      </div>
      <div id="form-container"> 
      </div>
   </div>
</template>
<script>
import {mapGetters,mapActions,mapMutations,mapState} from 'vuex' 
import {HTTP} from '../../../http-common'
export default {
    name : 'Checkout',
    data(){
         return{
             items : {
                items :{},
                totalAmount :'',
             },
             paymentMethodId : 1001,
             shippingAddressId : null,
             billingAddressId : null,
             currentPath :  window.location.href,
             url : '',
             encrypted_data: '',
             access_code : ''
         }
    },
    mounted(){
         this.fetchAddresses();
         this.fetchPaymentMethods();
         this.fetchItems(); 
    },
    methods : {
       ...mapActions({
            fetchAddresses : 'address/all', 
            fetchPaymentMethods : 'checkout/fetchPaymentMethods',
       }),
       placeOrder(){
          if(!this.shippingAddressId){
              alert('Select Or Create Address');
              return false;
          }
          
          let loader = this.$loading.show({ 
                    canCancel: true,
                    onCancel: this.onCancel,
         });  
          HTTP.post('place-order',{
             itemIds : this.itemIds,
             shippingAddressId : this.shippingAddressId,
             billingAddressId : this.billingAddressId,
             paymentMethodId : this.paymentMethodId,
             origin : window.location.origin
          }).then(({data})=> {
            //  console.log(data) 
            // //  this.url = data.url
            // //  this.access_code = data.access_code
            // //  this.encrypted_data = data.encrypted_data
            // //  document.redirect.submit()
            // const formContainer = document.getElementById('form-container');
            // formContainer.innerHTML = data.form;
 
            // document.getElementById('paymentForm').submit();
             window.location = data.url
               // this.$router.push({
               //       name: 'Pay',
               //       query : data ,
               // });
          }).finally(()=> {
             loader.hide();
          });
       },
       fetchItems(){
         let itemIds = JSON.parse(this.$route.query.itemIds);  
          HTTP.post('items-by-ids',{
             itemIds : itemIds,
          }).then(({data})=> {
             this.items = data
          });
       }, 
    },
    computed : {
       ...mapGetters({
           addresses : 'address/all',
           paymentMethods : 'checkout/getPaymentMethods',
           
         domainProfile : 'domain/profile',
       }), 
      itemIds(){
         let itemIds = [];
            this.items.items.forEach(item => { 
                     itemIds.push(item.id);            
                  });
               return itemIds;
      }
    },
    watch:{
        addresses(){
           this.addresses.forEach(address => {
                if(address.address_type_id == 1){
                   this.shippingAddressId = address.id;
                   this.billingAddressId = address.id;
                }
           });
        }
    }
}
</script>
 