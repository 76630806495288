<template>
   <div class="main-content-wrap section-ptb product-details-page">
      <div class="container">
         <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-5">
               <div class="product-details-images">
                  <div class="product_details_container">
                     <div class="product_big_images-top">
                        <div class="portfolio-full-image tab-content">
                           <div class="img-showcase" v-if="item">
                              <img :src="item.certificate_image ? 'https://erp44.com/public/warehouse/certificate-images/'+item.certificate_image.filename:'http://placehold.jp/150x150.png'" class=" img1"> 
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>  
            <div class="col-xl-7 col-lg-7 col-md-7">
               <div class="product_details_info">
                  <h2 v-if="item">
                     <!-- {{item.iname ?? ''}} -->
                        {{item.product.name ?? 'NO'}}
                  <!-- -{{item.product_grade.alias ?? 'NO'}} -->
                  <!-- -
                  {{item.ratti.rati_standard ?? 'NO'}} -->
                  <!-- - -->
                  ({{ item.weight }}MG)<br/> 
                     </h2>
                  <ul class="pro_dtl_prize ml-0 pl-0">
                     <li v-if="item"> {{ currencySign }}{{ convertPrice(item.amounts.mrpAmount) }}</li>
                     <br/>
                     <p><small>GST and Delivery Charges are included</small></p>
                    
                     <span v-if="item">SKU : {{ domainProfile.id }}{{ item.gin ?? '' }}</span>
                     <!-- <p v-if="item"> An Original  {{ item ? item.product.alias : ''  }} and weight {{item.weight}}mg</p> -->
                  </ul>
                  <div class="pro_details">
                     <!-- The Weight of Natural Yellow Sapphire is about 4.8 carats. The measurements are 9.62mm x8.31mm x5.56mm(length x width x depth). The shape/cut-style of this Natural Yellow Sapphire is Rectangular Cushion. This 4.8 carat Natural Yellow Sapphire is available to order and can be shipped anywhere in the world.Gemstone cerification is provided by GemLab. -->
                     <!-- <br>
                     <br> -->
                  </div>
                  <div class="row mt-3">
                     <div class="col-md-12">
                        <button v-if="isItemInCart(item.id) " type="submit" name="add" class="btn-clas btn btn-success btn-lg"><i class="ion-android-cart"></i> Added</button>
                        <button style="border:none" v-else type="submit" name="add" class="btn-clas btn btn-success " @click="addToCart(item.id)"><i class="ion-android-cart" ></i> Add To Cart</button><router-link :to="{name : 'Checkout',query : {itemIds : `[${item.id}]`}}" class="d-inline ml-3" ><button name="buynw" class="btn-clas btn btn-primary border-0"><i class="icon-wallet"></i> Buy Now</button></router-link>
                     </div>
                     <!-- <div class="col-md-6">
                     </div> -->
                  </div>
                  <ul class="pro_dtl_btn ml-0">
                     <li></li>
                     <!-- <li v-else><button<li v-else type="submit" name="add" class="btn-class2 btn btn-success btn-lg" @click="addToCart(item.id)"><i class="ion-android-cart" ></i> Add To Cart</button></li> -->
                     <li></li>
                  </ul>
                  <ul class="pro-icon-cls">  </ul>
               </div>
            </div>
         </div>
         <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
               <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Description</a>
            </li>
            <li class="nav-item">
               <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Additional Information</a>
            </li>
         </ul>
         <div class="tab-content commonBottomMargin" id="myTabContent">
            <div class="tab-pane fade show text-secondary active p-4" id="home" role="tabpanel" aria-labelledby="home-tab">
               <!-- The Weight of Astrological Yellow Sapphire Std 1.25 CT (2.08 Ratti) is 250 mg. The measurements are 8.04 X 6.79 X 2.22 (length x width x depth).<br>The shape/cut-style of this gemstone is Oval. This Gemstone is available to order and can be shipped
                anywhere in the world.<br> Gemstone certification is provided by GemLab. This Astrological Yellow Sapphire Gemstone originates from Sri Lanka. -->
                </div>
            <div class="col-md-9 col-sm-12 col-12 tab-pane fade mt-2" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <table class="col-md-9 col-sm-12 col-12  ml-0 table table-responsive ">
                    <tbody class="bg-white text-secondary">
                        <tr>
                            <td  v-if="item" >Weight : {{ item.weight ?? '' }}mg
                            </td>
                        </tr> 
                        <tr>
                            <td v-if ="item">Ratti :    {{item.ratti.rati_standard ?? ''   }}
                            </td> 
                        </tr>
                        <tr>
                            <td v-if ="item">Color :   {{item.color.color ?? ''   }}
                            </td>
                        </tr>
                        <tr>
                            <td v-if ="item">Clarity :   {{item.clarity.clarity ?? ''   }}
                            </td> 
                        </tr>
                        <tr>
                            <td v-if ="item">Grade :   {{item.product_grade.alias ?? ''   }}
                            </td>
                        </tr>
                        <tr>
                            <td v-if ="item">Origin :   {{item.origin.origin ?? ''   }}
                            </td>
                        </tr>
                        <tr>
                              <td v-if ="item">Shape :   {{item.shape.shape ?? ''   }}
                            </td> 
                        </tr>
                        <tr>
                             <td v-if="item">Width :     {{item.width ?? ''   }}
                            </td>
                        </tr>
                        <tr>
                            <td v-if="item">Length :   {{item.length ?? ''   }}
                            </td>
                        </tr>
                        <tr>
                            <td v-if="item">Depth :   {{item.depth ?? ''   }}
                            </td>
                        </tr> 
                    </tbody>
                </table>
            </div>
            
            </div>
      </div>
   </div>
</template>
<script>

import {mapActions,mapGetters} from 'vuex';
export default {
    name: 'ItemDetails',  
    created(){ 
            this.fetchItem(this.$route.params.itemId);
            this.fetchAll();
    }, 
       mounted(){ 
            
       },
    methods:{ 
        ...mapActions({
            fetchItem : 'frontend/fetchItem', 
            fetchAll : 'cart/all', 
        }),  
        existAddToCard(itemId){
             if(this.$store.state.cart.cartItemIds){
            return this.$store.state.cart.cartItemIds.includes(itemId);
            }
      },
      addToCart(itemId){
         if(localStorage.getItem('authToken')){
            
         // this.inCart = true; 
         this.$store.dispatch('cart/save',itemId)
               .then((data) => {
                     if(data.errors){
                        this.errors = data.errors;
                     }else{
                        this.inCart = true;
                        this.fetchAll();
                        this.$router.push({name : 'Cart'});
                     }
               });
         }else{
            localStorage.setItem('redirect',this.$route.fullPath);
            this.$router.push({name: 'Login'});
         }
      },
      isItemInCart(itemId){
         var exists = this.carts.some(function(cart) {
             return cart.item.id == itemId;
         });
         return exists ? true : false; 
      }, 
    }, 
    computed :{
       ...mapGetters({
           item : 'frontend/getItem', 
           carts : 'cart/getAll',  
           domainProfile : 'domain/profile', 
       }), 
    }, 
  watch: {
    item(newValue, oldValue) {
       this.$store.dispatch('frontend/convertPrice', this.item.amounts.mrpAmount ?? '')
                    .then(result => {
                        this.convertedPrice = result;
                    })
                    .catch(error => {
                        console.error(error);
                    }); 
    },
  },
}
</script>

<style scoped>
  .cart{
       background-color:lightgreen
   }
</style>