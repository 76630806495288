import axios from 'axios';
export const HTTP = axios.create({
// baseURL: `http://gemlab.9gem.test/api/`,
  // baseURL: `https://erp44.com/api/`,
  baseURL: `https://9gem.net/api/`,
  // baseURL: `http://localhost/erp/api/`,
  // withCredentials: true, 
});
HTTP.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('authToken')}`;
HTTP.defaults.headers.common['domain'] = window.location.hostname
HTTP.defaults.headers.common['Access-Control-Allow-Origin'] = `*`;
HTTP.defaults.headers.common['Access-Control-Allow-Methods'] = `GET, POST, PUT, DELETE, OPTIONS`;
HTTP.defaults.headers.common['Access-Control-Allow-Headers'] = `Content-Type`;
HTTP.defaults.headers.common['Content-Type'] = `text/html; charset=UTF-8`;
HTTP.paymentUrl = 'https://erp44.net';
// HTTP.paymentUrl = 'https://vue.9gem.net';
// HTTP.paymentUrl = 'http://localhost/razorpay_testing';
 
// HTTP.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

 